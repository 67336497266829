import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cart: [],
  totalAmount: 0
}

export const userDataSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { id, price, discounted_price, discount_percentage, flash_sale_price, quantity } = action.payload;
      let actualPrice = price
      if (flash_sale_price > 0) {
        actualPrice = flash_sale_price
      } else if (discounted_price > 0) {
        actualPrice = discounted_price
      } else if (discount_percentage > 0) {
        actualPrice = price - (discount_percentage / 100) * price
      }
      const existingItem = state.cart.find(item => item.id === id);
      state.totalAmount += actualPrice * quantity
      if (existingItem) {
        existingItem.quantity += quantity;
      } else {
        state.cart.push({ ...action.payload });
      }
    },
    removeToCart: (state, action) => {
      const { id, price, discounted_price, discount_percentage, flash_sale_price, quantity } = action.payload;
      let actualPrice = price
      if (flash_sale_price > 0) {
        actualPrice = flash_sale_price
      } else if (discounted_price > 0) {
        actualPrice = discounted_price
      } else if (discount_percentage > 0) {
        actualPrice = price - (discount_percentage / 100) * price
      }
      const existingItem = state.cart.find(item => item.id === id);
      state.totalAmount -= actualPrice * quantity
      if (existingItem.quantity > 1) {
        existingItem.quantity -= 1;
      } else {
        const index = state.cart.findIndex(item => item.id === id);
        if (index !== -1) {
          state.cart.splice(index, 1);
        }
      }
    },
    removeProductToCart: (state, action) => {
      const { id, price, discounted_price, discount_percentage, flash_sale_price, quantity } = action.payload;
      let actualPrice = price
      if (flash_sale_price > 0) {
        actualPrice = flash_sale_price
      } else if (discounted_price > 0) {
        actualPrice = discounted_price
      } else if (discount_percentage > 0) {
        actualPrice = price - (discount_percentage / 100) * price
      }
      state.totalAmount -= actualPrice * quantity
      const index = state.cart.findIndex(item => item.id === id);
      if (index !== -1) {
        state.cart.splice(index, 1);
      }
    },
    clearCart: (state, action) => {
      state.totalAmount = 0
      state.cart = []
    },
  },
})


// Action creators are generated for each case reducer function
export const { addToCart, removeToCart, removeProductToCart, clearCart } = userDataSlice.actions

export default userDataSlice.reducer

